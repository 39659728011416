<template>
 <div class="content">
   <app-cells position="between">
     <h1 class="title title--theme">Статистика: Договоры</h1>
     <v-select
       @search="onFilterSearch"
       noDrop
       label="id"
       placeholder="Поиск по названию"
       class="select select--minw"
     />
   </app-cells>
   <v-client-table
     @row-click="onRowClick"
     :data="table.items"
     :columns="table.columns"
     :options="table.options"
     ref="tableDefault"
     class="table-default table-default--dynamic"
   >
     <div slot="delegation_name" slot-scope="props" class="table-default__left">
       {{ props.row.delegation_name }}
     </div>
     <template #participants_count="props">
       <span>Всего: {{ props.row.participants_count }}</span><br>
       <span>По квоте: {{ props.row.participants_count_quota }}</span><br>
       <span>Вне квоты: {{ props.row.participants_count_quota_out }}</span>
     </template>
     <template #legal.all="props">
       <span>Юр: {{ props.row.legal.all }}</span><br>
       <span>Физ: {{ props.row.individual.all }}</span>
     </template>
     <template #legal="props">
       <span class="grey">Черновики: {{ props.row.legal.draft }}</span>&nbsp;
       <span class="red">Отклонены: {{ props.row.legal.reject }}</span><br>
       <span class="yellow">На рассмотрении: {{ props.row.legal.review }}</span>&nbsp;
       <span class="green">Приняты: {{ props.row.legal.accept }}</span>
     </template>
     <template #individual="props">
       <span class="grey">Черновики: {{ props.row.individual.draft }}</span>&nbsp;
       <span class="red">Отклонены: {{ props.row.individual.reject }}</span><br>
       <span class="yellow">На рассмотрении: {{ props.row.individual.review }}</span>&nbsp;
       <span class="green">Приняты: {{ props.row.individual.accept }}</span>
     </template>
     <template #contracts_amount="props">
       {{ new Intl.NumberFormat('ru-RU').format(props.row.contracts_amount) }} ₽
     </template>
     <template #paid_sum="props">
       {{ new Intl.NumberFormat('ru-RU').format(props.row.paid_sum) }} ₽
     </template>
   </v-client-table>
 </div>
</template>

<script>
import { getContractStat } from '@/http'
import { debounce } from 'lodash'

export default {
  name: 'ContractStat',
  data() {
    return {
      table: {
        items: [],
        columns: [
          'delegation_name',
          'participants_count',
          'legal.all',
          'legal',
          'individual',
          'contracts_amount',
          'paid_sum'
        ],
        options: {
          headings: {
            'delegation_name': 'Делегация',
            'participants_count': 'Участников',
            'legal.all': 'Договоров',
            'legal': 'Юр Статусы',
            'individual': 'Физ Статусы',
            'contracts_amount': 'Сумма',
            'paid_sum': 'Оплачено',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 1000,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Делегации отсутствуют',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => 'table-default__row',
        },
      },
      participants_done: false,
      participants_print: false,
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems(delegation_name = '') {
      getContractStat(delegation_name)
        .then(response => {
          this.table.items = response.data
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'contracts-list', params: { delegation_id: row.row.delegation_pk } })
    },
    onFilterSearch(search, loading) {
      loading(true)
      this.searchFilter(loading, search, this)
    },
    searchFilter: debounce((loading, search, vm) => {
      vm.fetchItems(search)
      loading(false)
    }, 350),
  }
}
</script>

